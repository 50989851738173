import styled, {css} from 'styled-components';
import {Modal} from "react-bootstrap";

const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
const StyledBody = styled(Modal.Body)`
  padding: 0px;
`;
const StyledModal = styled(Modal)`
  .modal-content{
    border-radius: 12px;
  }
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .delete-popup { 
    top: 30%;
    width: 400px;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
      padding: 10px;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
`;
const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({justify}) => justify ? 'center' : 'space-between'};
  align-items: center;
  background: #4798D9;
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  @media (max-width: 500px){
    padding: 20px 8px;
  }
`;
const PopupDescription = styled.div`
  width: 100%;
  padding: ${({padding}) => padding ? '0px 25px' : '0px 25px 25px'};
  > div{
    font-family: ${RegularFont};
    font-size: 21px;
    color: #3E89C4;
    line-height: 26px;
    text-align: center;
    margin: 8px 0px;
  }
  > div:nth-child(2){
    font-family: ${MediumFont};
    font-size: 25px;
    color: #282C37;
    line-height: 30px;
    text-align: center;
    margin: 8px 0px;
  }
  > div: last-child{
    margin: 25px 0px;
  }
`;

const StyledModalV2 = styled(Modal)`
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
    margin: ${({change,previewHeight}) => change ? '150px auto' : previewHeight ? '150px auto' : '0 auto'};
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change,previewHeight}) => change ? '573px' : previewHeight ? previewHeight : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: 25px 0 25px 0;
  }
  
  .modal-container {
   width: 100%;
   display: block;
   > div: nth-child(1) {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 15px 15px 0 0;
    }
   }
   > div: nth-child(2) {
    width: 100%;
    display:flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color:#0D4270;
    text-decoration: underline;
    margin-top: 35px;
   }
  }
`;

const PopupHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: ${({background}) => background ? background : 'linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%)'};
  border-radius: 6px 6px 0 0;
  > div: nth-child(1) {
    width:65%;

    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#FFF;
    margin: auto;
    padding-left: 25px;
  }
  > div: nth-child(2) {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    >div{
      width: ${({width}) => width ? width : '24px'};
      height: ${({width}) => width ? width : '24px'};
      margin: auto;
      margin-right: 15px;
      >img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

`

const CalendarWrapper = styled.div`
  padding: ${({padding}) => padding ? padding : '25px'};
`

const HeadingText = styled.div`
font-family: Rubik;
font-size: 18px;
line-height: 24px;
color: #005C87;
padding-bottom 25px;
text-align: center;
`

const DateButton = styled.div`
width: ${({width}) => width ? 'calc((100% - 25px) / 2)' : '100%'};
background: ${({background}) => background ? background : 'rgba(253, 113, 117, 1)'};
box-shadow: ${({shadow}) => shadow ? shadow : ''};
height: 50px;
display: flex;
align-items: center;
border: ${({border, borderValue}) => borderValue?`1px solid ${borderValue}`: border ? '1px solid rgba(253, 113, 117, 1)' : '0'};
justify-content: center;
font-family: rubik-medium;
font-size: 18px;
line-height: 24px;
color: ${({color}) => color ? color : '#fff'};
margin-bottom: ${({margin}) => margin ? '15px' : '0px'};
border-radius: 6px;
cursor: pointer;
`

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
flex-wrap:wrap;

`
const FieldTitle = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 20px;
  color: ${({color}) => color ? color :'#20495E'};
  font-family: rubik-medium;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  display: flex;
  .required-field{
    color: rgba(253,113,117,1)
  }
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const ChallengeButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background? background:"rgba(253, 113, 117, 1)"};
  height: 50px;
  cursor: pointer;
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color: #FFF;
  border-radius: 6px;
  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;

const DateContainer = styled.div`
 width: ${({width}) => width ? width :'100%'};
 height:50px;
 background-color: ${({bgColor, disable, disabled}) =>disabled?"#ffffff": disable?"#ffffff": bgColor ? 'rgba(156, 156, 156, 0.05)' : '#fffff'};
 border: ${({border}) => border ? '1px solid #005C874D': '1px solid #005C874D'};
 border-radius:6px;
 padding:15px;
 display: flex;
 z-index:100000;
 cursor:pointer;
 cursor: ${({ disabled }) => disabled? "not-allowed":"pointer"};
 .text{
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    font-family: ${({ disable, disabled }) =>disabled?"rubik": disable? "rubik":"rubik"};
    color: ${({ disable, disabled }) =>disabled?"#005C8799": disable? "#005C8799":"#005C87"};
 }
 .icon{
    width:22px;
    height:20px;
    display:flex;
    margin:auto;
    margin-right:0px;
    >img{
      width:22px;
      height:20px;
    }
 }
`;

const Background = styled.div`
 width:800px;
 height:1000px;
 background:red;
 display:flex;
 
`;

const DateCalendarWrapper = styled.div`
width: ${({width}) => width ? width :'100%'};
display: flex; 
z-index: 2;
`;


const DateCalendarWrapperNew = styled.div`
width: ${({width}) => width ? width :'100%'};
display: flex; 
justify-content:space-between;
`;

export { StyledModal, StyledBody,DateCalendarWrapperNew, SubmitContainer, PopupDescription, StyledModalV2, PopupHeader, CalendarWrapper, HeadingText, DateButton, ButtonWrapper, FieldTitle, ChallengeButton, DateContainer, Background, DateCalendarWrapper}